import React, { FunctionComponent, useContext, useState } from "react";
import { Link } from "../Link";
import sanityImageUrlBuilder from "../../utils/imageUrlBuilder";
import { useSiteMetadata } from "../../hooks/useSiteMetadata";
import { ImageInterface, PortableText, MultiLineHeadline, SanityColorList, Slug } from "../../types/SanityTypes";
// @ts-ignore
import BlockContent from "@sanity/block-content-to-react";
import "./styles.scss";
import { Container } from "react-bootstrap";
import ModalBlock, { ModalBlockInterface } from "../../components/Modal";
import { LocalizedContext } from "../../services/LocalizedContextService";
import { RichTextSerializers } from "../../utils/richTextSerializers";

export interface FeaturePromoBlockInterface {
  heading: string;
  bgColor: SanityColorList;
  theme?: {
    name: string;
  };
  _rawTextBody: PortableText;
  _rawFeaturePromoImage: ImageInterface;
  _rawPromoBackground: ImageInterface;
  promoBackground: ImageInterface;
  imageAlignment?: string;
  imageHeight: number;
  imageWidth: number;
  ctaLabel: string;
  ctaLink?: {
    _id: string;
    _type: string;
    __typename: string;
    type: string;
    params?: string;
    externalLink?: string;
    internalLink?: {
      _id: string;
      __typename: string;
      slug: {
        current: string;
      };
      parentSection?: ParentSection;
    };
    headline?: MultiLineHeadline;
    _rawIntroduction?: PortableText;
    surveyBlock?: {
      submitCTA: string;
      questions: {
        question: string;
        questionType: string;
      }[];
    };
    fragmentId?: string;
  };
  // For Preview
  textBody?: PortableText;
  featurePromoImage?: ImageInterface;
}

interface ParentSection {
  slug: { current: string };
  parentSection?: ParentSection;
}

export const PureFeaturePromoBlock: FunctionComponent<FeaturePromoBlockInterface> = ({
  heading,
  bgColor,
  _rawTextBody,
  _rawFeaturePromoImage,
  _rawPromoBackground,
  imageHeight,
  imageWidth,
  textBody,
  featurePromoImage,
  ctaLabel,
  ctaLink,
  theme,
  imageAlignment
}) => {
  const [showModal, setShowModal] = useState(false);
  const { sanityId, sanityDataset } = useSiteMetadata();
  const { sanityProductLandingPage } = useContext(LocalizedContext);
  const urlBuilder = sanityImageUrlBuilder({
    projectId: sanityId,
    dataset: sanityDataset
  });

  const createH2Color: { [key: string]: string } = {
    Blue: "white",
    Green: "var(--blue-color)",
    Gray: "#18191A"
  };
  const btnColor = bgColor.title === "Blue" ? "btn-primary-green" : "btn-primary-blue";
  const h2Color = createH2Color[bgColor.title];

  function getParentPath(): string {
    if (!ctaLink || !ctaLink.internalLink) return "";

    const getSlugPath = (section?: ParentSection): string => {
      if (!section) return "";
      const parentSlugPath = section.parentSection ? getSlugPath(section.parentSection) : "";
      return parentSlugPath ? `${parentSlugPath}/${section.slug.current}` : section.slug.current;
    };

    return getSlugPath(ctaLink.internalLink.parentSection);
  }

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  const getCtaURL = () => {
    if (ctaLink?.__typename === "SanityLink") {
      if (ctaLink.type === "internal" && ctaLink.internalLink) {
        if (
          ctaLink.internalLink?.__typename === "SanityProduct" ||
          ctaLink.internalLink?.__typename === "SanityProductLinePage"
        ) {
          return buildUrl(`/${sanityProductLandingPage?.slug?.current}/${ctaLink.internalLink.slug.current}`, ctaLink.params);
        } else {
          return buildUrl(`/${getParentPath()}/${ctaLink.internalLink.slug.current}`, ctaLink.params);
        }
      } else {
        return ctaLink.externalLink;
      }
    } else if (ctaLink?.__typename === "SanityAnchorElement") {
      return `#${ctaLink?.fragmentId}`;
    }
  };

  const buildUrl = (base: string, params?: string): string => {
    return params ? `${base}/${params}` : base;
  };

  const createThemeClassName = (className: string | undefined) => {
    return className ? `theme--${className?.replace(" ", "").toLocaleLowerCase()}` : "";
  };

  const renderCTA = () => {
    if (ctaLink) {
      if (ctaLink?.__typename === "SanityModalCTABlock" || ctaLink?._type === "modalCTABlock") {
        return (
          <>
            <button className={btnColor + " btn-primary"} onClick={handleShow} id="featurePromoBlockLink">
              {ctaLabel}
            </button>
            <ModalBlock
              data={ctaLink as unknown as ModalBlockInterface["data"]}
              showModal={showModal}
              handleClose={handleClose}
            />
          </>
        );
      } else {
        return (
          <Link
            _id={ctaLink?.internalLink?._id || ""}
            to={getCtaURL() || ""}
            className={btnColor + " btn-primary"}
            {...(ctaLink.__typename === "SanityArticle" && { "data-analytics-event4": "" })}
          >
            {ctaLabel}
          </Link>
        );
      }
    }
  };

  const image = _rawFeaturePromoImage || featurePromoImage;
  const text = _rawTextBody || textBody;

  const defineImageWidth = (defaultValue: number) => {
    return imageWidth || defaultValue;
  };
  const defineImageHeight = (defaultValue: number) => {
    return imageHeight || defaultValue;
  };

  const arrangementOrder = imageAlignment === "Left" ? "image-left" : "image-right";

  return (
    <section data-testid="feature-promo" className={`feature-promo ${theme && createThemeClassName(theme.name)}`}>
      <div
        className={`outer-container ${arrangementOrder}`}
        style={
          _rawPromoBackground && _rawPromoBackground.asset
            ? { background: `url(${_rawPromoBackground.asset.url})` }
            : { background: bgColor.value }
        }
      >
        <Container fluid>
          <div className="feature-promo-block">
            <div className="images-container">
              {image && (
                <div className="feature-promo-block-image">
                  <picture data-testid="feature-promo-block-image">
                    <source
                      media={"(min-width: 992px)"}
                      srcSet={
                        urlBuilder
                          .image(image)
                          .auto("format")
                          .quality(80)
                          .width(defineImageWidth(500))
                          .height(defineImageHeight(900))
                          .format("webp")
                          .url() as string
                      }
                    />
                    <source
                      media={"(min-width: 576px)"}
                      srcSet={
                        urlBuilder
                          .image(image)
                          .auto("format")
                          .quality(80)
                          .width(defineImageWidth(450))
                          .height(defineImageHeight(850))
                          .format("webp")
                          .url() as string
                      }
                    />
                    <img
                      src={
                        urlBuilder
                          .image(image)
                          .auto("format")
                          .quality(80)
                          .width(defineImageWidth(350))
                          .height(defineImageHeight(700))
                          .format("webp")
                          .url() as string
                      }
                      loading={"lazy"}
                      alt={image.alt}
                      className="img-fluid"
                    />
                  </picture>
                </div>
              )}
            </div>
            <div className="copy-container">
              <div className="copy-position">
                {heading && (
                  <h2 style={{ color: h2Color }} className="header-title">
                    {heading}
                  </h2>
                )}
                {text && (
                  <div className="body-copy" style={{ color: h2Color }}>
                    <BlockContent blocks={text} serializers={RichTextSerializers()} />
                  </div>
                )}
                {ctaLink && ctaLabel && (
                  <div className="btn-position" data-testid="feature-promo-block-link">
                    {renderCTA()}
                  </div>
                )}
              </div>
            </div>
          </div>
        </Container>
      </div>
    </section>
  );
};

const FeaturePromoBlock: FunctionComponent<FeaturePromoBlockInterface> = ({
  heading,
  bgColor,
  _rawTextBody,
  textBody,
  featurePromoImage,
  _rawFeaturePromoImage,
  imageHeight,
  imageWidth,
  ctaLabel,
  ctaLink,
  theme,
  imageAlignment,
  promoBackground,
  _rawPromoBackground
}) => {
  return (
    <PureFeaturePromoBlock
      heading={heading}
      bgColor={bgColor}
      _rawTextBody={_rawTextBody}
      _rawFeaturePromoImage={_rawFeaturePromoImage}
      _rawPromoBackground={_rawPromoBackground}
      imageHeight={imageHeight}
      imageWidth={imageWidth}
      textBody={textBody}
      featurePromoImage={featurePromoImage}
      ctaLabel={ctaLabel}
      ctaLink={ctaLink}
      theme={theme}
      imageAlignment={imageAlignment}
      promoBackground={promoBackground}
    />
  );
};

export default FeaturePromoBlock;
